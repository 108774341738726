<template>
  <div class="grid grid-flow-col auto-cols-min gap-24 justify-center">
    <div class="tabs w-auto">
      <router-link
        v-for="(item, index) in stepList"
        v-slot="{ navigate }"
        :key="`tab-${item.title}-${index}`"
        :to="{ name: item.path }"
        custom
      >
        <div
          class="!cursor-default"
          :class="['tab', item.isActive() && 'isActive']"
          :data-test-id="item.name"
          v-bind="item.canClick ? { onClick: navigate } : {}"
        >
          <Icon :icon="item.icon" class="icon-md" />
          <span class="title text-nowrap">{{ item.title }}</span>
        </div>
      </router-link>
    </div>
  </div>

  <div class="grid grid-cols-12 gap-24 justify-center mt-32">
    <RouterView />
  </div>

  <HelpChat />
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { useI18n } from 'vue-i18n';
import HelpChat from '@/components/HelpChat.vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';

const { t } = useI18n();
const store = useUserStore();
const route = useRoute();

const stepList = [
  {
    title: t('register.stepper.create-account'),
    icon: 'mdi:account-circle',
    path: 'CreateAccount',
    isActive() {
      return [
        'CreateAccount',
        'EmailSend',
        'Verify',
        'AccountTerms',
        'AccountLinked',
      ].includes(route.name);
    },
  },
  {
    title: t('register.stepper.config-workspace'),
    icon: 'mdi:business',
    path: 'ConfigSpace',
    isActive() {
      return ['ConfigSpace'].includes(route.name);
    },
    canClick: {
      get () {
        return !!store.$state.company?._id;
      },
    },
  },
  {
    title: t('register.stepper.plan'),
    icon: 'mdi:license',
    path: 'ConfigPayment',
    isActive() {
      return [
        'ConfigPayment',
        'ConfigPaid',
        'ConfigBilling',
        'AccountInvoiced',
      ].includes(route.name);
    },
    canClick: {
      get () {
        return !!store.$state.company?._id;
      },
    },
  },
  {
    title: t('register.stepper.invite-team'),
    icon: 'mdi:users-group',
    path: 'ConfigInvite',
    isActive() {
      return ['ConfigInvite', 'AccountInvited'].includes(route.name);
    },
  },
];
</script>
